
import { Inject } from 'inversify-props';
import { Component, Mixins } from 'vue-property-decorator';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import AnalysisBtn from '@/modules/common/components/ui-kit/analysis-btn.vue';
import RatesFilterItemsMixin from '../../mixins/rates-filters.mixin';

@Component({
    components: {
        AnalysisBtn,
        ExcelBtn,
        CustomSelect,
        CustomMultiSelect,
        LosDocumentFilter,
    },
})
export default class RatesActions extends Mixins(RatesFilterItemsMixin) {
    @Inject(UserServiceS) private userService!: UserService;

    get isLoading() {
        return this.ratesService.isLoading;
    }

    get currentPath() {
        return this.$route.path;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get isAnalysisDisabled() {
        return this.ratesFiltersService.isProviderAll
            || this.ratesFiltersService.isProviderCheapest;
    }

    get isCalendarView() {
        const { currentRouteName } = this;

        return !currentRouteName.includes('.table') && !currentRouteName.includes('.graph-');
    }

    get hotels() {
        return this.hotelsService.allHotels;
    }

    get isGraphView() {
        const { currentRouteName } = this;

        return currentRouteName.includes('.graph-');
    }

    get isTableView() {
        const { currentRouteName } = this;

        return currentRouteName.includes('.table');
    }

    get isAnalysisMode() {
        return this.currentRouteName.includes('.analysis');
    }

    get ratesRouteName() {
        return this.currentRouteName
            .split('.day-rate')[0]
            .replace(/\.(analysis|graph-\w+|table)/g, '');
    }

    get calendarRoute() {
        return this.getRouteTo('');
    }

    get tableRoute() {
        return this.getRouteTo('.table');
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    getRouteTo(suffix: string) {
        if (this.isAnalysisMode) {
            const routeName = this.currentRouteName.replace(/\.analysis(\..+)/, '.analysis');

            return {
                name: `${routeName}${suffix}`,
                params: this.$route.params,
            };
        }

        return {
            name: `${this.ratesRouteName}${suffix}`,
            params: this.$route.params,
        };
    }

    goToGraphView() {
        const graphType = this.ratesService.isAllChannelsMode
            ? 'hotels'
            : 'compset';

        this.$router.push({
            name: `${this.ratesRouteName}.graph-${graphType}`,
            params: this.$route.params,
        });
    }
}
