import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import CompsetModel from '@/modules/compsets/models/compset.model';
import Item from '@/modules/common/interfaces/item.interface';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import NumberOfGuestsService, { NumberOfGuestsServiceS } from '@/modules/number-of-guests/number-of-guests.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesService, { RatesServiceS } from '../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../rates-filters.service';

@Component
export default class RatesFilterItemsMixin extends Vue {
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(RatesFiltersServiceS) ratesFiltersService!: RatesFiltersService;
    @Inject(RatesServiceS) protected ratesService!: RatesService;
    @Inject(CompsetsServiceS) protected compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @Inject(ProvidersServiceS) protected providersService!: ProvidersService;
    @Inject(RoomTypesServiceS) protected roomTypesService!: RoomTypesService;
    @Inject(MealTypesServiceS) protected mealTypesService!: MealTypesService;
    @Inject(NumberOfGuestsServiceS) protected numberOfGuestsService!: NumberOfGuestsService;
    @Inject(UserSettingsS) protected userSettingsService!: UserSettingsService;

    get losItems() {
        return this.documentFiltersService.losItems;
    }

    set los(value: number | null) {
        this.documentFiltersService.saveLos(value);
    }

    get los() {
        const { los } = this.documentFiltersService.storeState.settings;
        return los;
    }

    get compsetTypeItems(): Item[] {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return [];
        }

        return compsets
            .filter(compset => compset.ownerHotelId === Number(this.$route.params.hotelId))
            .map((compset: CompsetModel) => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
            }));
    }

    get compsetId() {
        return this.documentFiltersService.compsetId;
    }

    set compsetId(value) {
        if (value) {
            this.documentFiltersService.updateCompset(value);
        }
    }

    get competitorItems() {
        const { competitors } = this.compsetsService;

        if (!competitors) {
            return [];
        }

        return competitors.map(val => ({
            value: val,
            name: this.hotelsService.getHotelName(val),
        }));
    }

    get competitors() {
        const { competitors } = this.documentFiltersService;

        if (!competitors) {
            return [];
        }

        return competitors;
    }

    set competitors(value: number[]) {
        this.documentFiltersService.competitors = value;
    }

    get providerItems(): Item[] {
        const routeName = this.$route.name || '';
        const { currentCompset } = this.compsetsService;
        const isAnalysisMode = routeName.includes('analysis');

        if (!currentCompset) {
            return [];
        }

        const providerList = Array
            .from(new Set(currentCompset.rateProviders));

        if (isAnalysisMode) {
            const list = providerList
                .filter(provider => !['all', 'cheapest'].includes(provider))
                .map(provider => ({
                    value: provider,
                    name: this.getProviderLabel(provider),
                }));

            if (['all', 'cheapest'].includes(this.provider!)) {
                this.provider = list[0].value;
            }

            return list;
        }

        const list = providerList
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
            }));

        return list;
    }

    get provider() {
        if (this.compsetsService.currentCompset
            && this.ratesFiltersService.currentProvider
            && !this.compsetsService.currentCompset.rateProviders.includes(this.ratesFiltersService.currentProvider)
            && this.ratesFiltersService.currentProvider !== ScanDisabledProviders.ALL
        ) {
            return this.compsetsService.currentCompset.rateProviders[0];
        }

        return this.ratesFiltersService.currentProvider;
    }

    set provider(value) {
        this.ratesFiltersService.saveProvider(value);
    }

    get posItems(): Item[] {
        const { posRatesItems, posMarketItems } = this.documentFiltersService;
        const isRatesPage = this.$route.name ? this.$route.name.includes('rate') : false;
        return isRatesPage ? posRatesItems : posMarketItems;
    }

    get pos() {
        return this.documentFiltersService.storeState.settings.pos;
    }

    set pos(value) {
        this.documentFiltersService.savePos(value);
    }

    get priceTypeItems(): Item[] {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`price.${value}`) as string,
        }));
    }

    get priceType() {
        return this.ratesFiltersService.priceType;
    }

    set priceType(value) {
        this.ratesFiltersService.priceType = value;
    }

    get numberOfGuestsItems(): Item[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
            value: item,
            name: this.$tc('filters.guests', item, [item]),
        }));
    }

    get numberOfGuests() {
        return this.ratesFiltersService.currentNumberOfGuests;
    }

    set numberOfGuests(value) {
        this.ratesFiltersService.saveNumberOfGuests(Number(value));
    }

    get roomTypeItems(): Item[] {
        if (!this.roomTypesService.rooms) {
            return [{
                value: ANY_ROOM_TYPE.id,
                name: this.$tc(ANY_ROOM_TYPE.displayName),
            }];
        }

        return this.roomTypesService.rooms
            .map((roomType: RoomTypeModel) => ({
                value: roomType.id,
                name: this.$te(roomType.displayName) ? this.$tc(roomType.displayName) : roomType.displayName,
            }));
    }

    get roomType() {
        return this.ratesService.storeState.settings.roomTypeId;
    }

    set roomType(value) {
        if (value) {
            this.ratesService.storeState.settings.roomTypeId = value;
        }
    }

    get mealTypeItems(): Item[] {
        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }
        return mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.id,
            name: this.$tc(mealType.displayName),
        }));
    }

    get mealType() {
        return this.ratesService.storeState.settings.mealTypeId;
    }

    set mealType(value) {
        if (value !== null) {
            this.ratesService.storeState.settings.mealTypeId = value;
        }
    }

    isFilterDisabled = this.ratesFiltersService.isFilterDisabled.bind(this.ratesFiltersService);

    private getProviderLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }
}
