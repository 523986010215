
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class RatesGraphTypeChanger extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    graphType: boolean = false;

    get compsetType() {
        const { currentCompset } = this.compsetsService;
        if (!currentCompset) {
            return null;
        }

        return this.$t(`compset.${currentCompset.type}`);
    }

    created() {
        this.graphType = this.$route.path.includes('graph-hotels');
    }

    change(checked: boolean) {
        if (this.userService.currentHotelId) {
            const routeName = checked ? 'graph-hotels' : 'graph-compset';
            const currentRoute = this.$route.name!.split('.');
            currentRoute.pop();
            this.$router.push({
                name: `${currentRoute.join('.')}.${routeName}`,
                params: { hotelId: this.userService.currentHotelId.toString() },
            });
        }
    }
}
