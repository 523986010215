
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: {
        CustomSelect,
        StyledSearchbar,
    },
})
export default class CurrencySwitcher extends Vue {
    @Inject(UserSettingsS) userSettingsService!: UserSettingsService;

    query: string = '';

    // NOTE: Adding support v-model for this component
    @Prop()
    private value!: string;

    public isLoading = false;

    get currencyItems() {
        const { allCurrencies } = this.userSettingsService;
        const currencies = allCurrencies
            ? allCurrencies.currencies
            : {};

        const currencyList = Object
            .entries(currencies)
            .map(([_, curr]) => ({
                name: `${curr.code}`,
                value: curr.code,
            }))
            .filter(
                item => (this.query
                    ? item.name
                        .toLowerCase()
                        .includes(this.query.toLowerCase())
                    : true),
            );

        currencyList.splice(0, 0, {
            name: this.$tc('filters.byPos'),
            value: null as unknown as string,
        });

        return currencyList;
    }

    get currentCurrency() {
        if (this.isVModelMode()) {
            return this.value;
        }

        return this.userSettingsService.displayCurrency;
    }

    set currentCurrency(value: string | null) {
        if (this.isVModelMode()) {
            this.$emit('input', value);
            return;
        }

        this.updateCurrency(value);
    }

    private updateCurrency(value: string | null) {
        try {
            this.isLoading = true;
            this.userSettingsService
                .updateDisplayCurrency(value);
        } finally {
            this.isLoading = false;
        }
    }

    updateQuery(newQuery: string) {
        this.query = newQuery;
    }

    private isVModelMode() {
        const { model } = this.$vnode.data as { [k: string]: any; };

        if (model) return true;

        return false;
    }
}
